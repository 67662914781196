<template>
  <div class="page-orders">
    <n-loader :loading="$var('load')" />
    <top-bar :title="$app.secure.clean($n.t('5.1'))" />
    <div class="container">
      <n-tabs :data="tabs" state>
        <template #tab="{item}">
          {{ item.title }}
        </template>
      </n-tabs>

      <n-tabs-content>
        <template #active>
          <div class="orders-wrap">
            <div v-for="item in applications" :key="item.id" class="item"
                 @click="$router.push({name: 'myApp', params: {id: item.id}})">
              <div>
                <div class="title">{{ item.name }}</div>
                <div class="volume">{{ item.volume }} кг</div>
              </div>
              <div class="date">
                {{ $app.date.format(item.createdAt, 'datetime') }}
                <n-icon icon="right-arrow" />
              </div>
            </div>
          </div>
        </template>

        <template #finish>
          <div class="orders-wrap">
            <div v-for="item in applications" :key="item.id" class="item"
                 @click="$router.push({name: 'myApp', params: {id: item.id}})">
              <div>
                <div class="title">{{ item.name }}</div>
                <div class="volume">{{ item.volume }} кг</div>
              </div>
              <div class="date">
                {{ $app.date.format(item.createdAt, 'datetime') }}
                <n-icon icon="right-arrow" />
              </div>
            </div>
          </div>
        </template>
      </n-tabs-content>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Index',
  data() {
    return {
      tabs: [
        { name: 'active', title: $app.secure.clean($n.t('5.2')), },
        { name: 'finish', title: $app.secure.clean($n.t('5.3')), },
      ],
      applications: [],
    }
  },
  computed: {
    tab() {
      return this.$route.query.tabsDefault
    },
  },
  watch: {
    tab() {
      if (this.tab === 'active') {
        this.load(true)
      } else {
        this.load(false)
      }
    },
  },
  created() {
    this.load(true)
  },
  methods: {
    load(isActive) {
      this.$var('load', true)
      if ($app.auth.user().isAdmin) {
        if (isActive) {
          $api.application.myApp().filter({ isActive: isActive, }).then((response) => {
            this.applications = response.data.content
          }).finally(() => {
            this.$var('load', false)
          })
        } else {
          $api.application.myInactiveApp().then((response) => {
            this.applications = response.data.content
          }).finally(() => {
            this.$var('load', false)
          })
        }
      } else {
        if (isActive) {
          $api.application.myUserApp().filter({ isActive: isActive, }).then((response) => {
            this.applications = response.data.content
          }).finally(() => {
            this.$var('load', false)
          })
        } else {
          $api.application.myUserInactiveApp().filter({ isActive: isActive, }).then((response) => {
            this.applications = response.data.content
          }).finally(() => {
            this.$var('load', false)
          })
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.page-orders {
  .orders-wrap {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #D9D9D9;
      padding-bottom: 12px;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      .title {
        font-weight: 500;
      }

      .volume {
        font-weight: 400;
        font-size: 16px;
        color: #102E4F;
      }

      .date {
        font-weight: 400;
        font-size: 16px;
        color: #8C8C8C;

        .n-icon {
          margin-left: 16px;
        }
      }
    }
  }

  .title {
    font-weight: 400;
    font-size: 18px;
    color: #102E4F;
  }
}
</style>
